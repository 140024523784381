import {
    HamburgerButton,
    Logo,
    LogoLink,
    Menu,
    NavDropdown,
    NavLink,
    NavProvider,
} from '@streamr/streamr-layout'
import { Link as LocalLink } from 'gatsby'
import React from 'react'
import { PrimaryButton } from 'streamr-ui'
import { display } from 'streamr-ui/css'
import { Desktop } from 'streamr-ui/css/consts'
import styled, { css } from 'styled-components'
import Display from '~shared/Display'
import Navbar from '~shared/Navbar'
import urls from '~utils/urls'

export const NETWORK = 'Network'

export const TOKEN = 'DATA token'

export const APPS = 'Apps'

export const RESOURCES = 'Resources'

const UseCoreButtonSize = css`
    --fontSize: 12px;
    --height: 32px;
`

const UseCoreButtonPaint = css`
    --bg: rgba(255, 255, 255, 0.3);
    --hoverBg: var(--bg);
    --activeBg: var(--bg);
    --disabledBg: var(--bg);
`

export function NetworkMenu() {
    return (
        <Menu>
            <Menu.Item as={LocalLink} to={urls.network}>
                Overview
            </Menu.Item>
            <Menu.Item as="a" href={urls.stakeAndEarn}>
                Staking
            </Menu.Item>
            <Menu.Item as="a" href={urls.apps.networkExplorer}>
                Explorer
            </Menu.Item>
        </Menu>
    )
}

export function AppsMenu() {
    return (
        <Menu>
            <Menu.Item as="a" href={urls.apps.hub}>
                Hub
            </Menu.Item>
            <Menu.Item as={LocalLink} to={urls.caseStudies.dataUnions}>
                Data Unions
            </Menu.Item>
            <Menu.Item as="a" href="https://thechat.app/">
                Thechat.app
            </Menu.Item>
        </Menu>
    )
}

export function ResourcesMenu({ children }) {
    return (
        <Menu>
            <Menu.Item as="a" href={urls.docs.index}>
                Docs
            </Menu.Item>
            <Menu.Item as="a" href="https://blog.streamr.network">
                Blog
            </Menu.Item>
            <Menu.Item as={LocalLink} to={urls.grants}>
                Grants
            </Menu.Item>
            <Menu.Item as={LocalLink} to={urls.faq}>
                FAQ
            </Menu.Item>
            <Menu.Item as={LocalLink} to={urls.project.ecosystem}>
                Ecosystem
            </Menu.Item>
            <Menu.Item as={LocalLink} to={urls.project.roadmap}>
                Roadmap
            </Menu.Item>
            <Menu.Item as={LocalLink} to={urls.whitepapers}>
                Whitepapers
            </Menu.Item>
            <Menu.Item as={LocalLink} to={urls.team}>
                Team
            </Menu.Item>
            {children}
        </Menu>
    )
}

const UnstyledNav = ({ className, light, afterLogoComponent }) => {
    const { highlight: current } = NavProvider.useState()

    return (
        <div className={className}>
            <Navbar>
                <Navbar.Item spread={!afterLogoComponent}>
                    <LogoLink href="/">
                        <Logo />
                    </LogoLink>
                </Navbar.Item>
                {afterLogoComponent}
                <Navbar.Item css={display([Desktop])}>
                    <NavDropdown
                        highlight={current === NETWORK}
                        toggle={<NavLink to={urls.network}>{NETWORK}</NavLink>}
                        menu={<NetworkMenu />}
                    />
                </Navbar.Item>
                <Navbar.Item css={display([Desktop])}>
                    <NavDropdown
                        highlight={current === TOKEN}
                        toggle={
                            <NavLink as={LocalLink} to={urls.token}>
                                {TOKEN}
                            </NavLink>
                        }
                    />
                </Navbar.Item>
                <Navbar.Item css={display([Desktop])}>
                    <NavDropdown
                        highlight={current === APPS}
                        toggle={<NavLink>{APPS}</NavLink>}
                        menu={<AppsMenu />}
                    />
                </Navbar.Item>
                <Navbar.Item css={display([Desktop])}>
                    <NavDropdown
                        highlight={current === RESOURCES}
                        toggle={<NavLink>{RESOURCES}</NavLink>}
                        menu={<ResourcesMenu />}
                    />
                </Navbar.Item>
                <Display as={Navbar.Item} mobile="none" desktop="block">
                    <div
                        css={`
                            padding-left: 8px;
                        `}
                    >
                        <PrimaryButton
                            tag="a"
                            href={urls.apps.hub}
                            size={UseCoreButtonSize}
                            paint={light ? UseCoreButtonPaint : undefined}
                        >
                            Launch Hub
                        </PrimaryButton>
                    </div>
                </Display>
                <Display as={Navbar.Item} desktop="none">
                    <HamburgerButton idle />
                </Display>
            </Navbar>
        </div>
    )
}

const Nav = styled(UnstyledNav)`
    pointer-events: auto;
    position: relative;
    z-index: 9999;

    ${({ light }) =>
        !!light &&
        css`
            color: #ffffff;
        `}
`

export default Nav
