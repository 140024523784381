import React from 'react'
import styled from 'styled-components'
import SocialLink from '../../Top/components/SocialLink'

const UnstyledSocialChannels = ({ className }) => (
    <ul className={className}>
        <li>
            <SocialLink channelKey="github" />
        </li>
        <li>
            <SocialLink channelKey="discord" />
        </li>
        <li>
            <SocialLink channelKey="twitter" />
        </li>
        <li>
            <SocialLink channelKey="reddit" />
        </li>
        <li>
            <SocialLink channelKey="youtube" />
        </li>
        <li>
            <SocialLink channelKey="telegram" />
        </li>
        <li>
            <SocialLink channelKey="medium" />
        </li>
        <li>
            <SocialLink channelKey="linkedIn" />
        </li>
    </ul>
)

const SocialChannels = styled(UnstyledSocialChannels)`
    border: 0 solid #d8d8d8;
    display: grid;
    grid-row-gap: 40px;
    grid-template-columns: repeat(5, auto);
    justify-content: space-between;
    margin: 0 auto;
    padding: 48px 0;

    @media (min-width: 768px) {
        display: flex;
        grid-row-gap: normal;
        grid-template-columns: none;
        max-width: none;
        padding: 48px 0;

        li {
            display: flex;
            justify-content: center;
        }
    }

    @media (min-width: 1200px) {
        border-width: 1px 0;
        justify-content: normal;

        li + li {
            margin-left: 70px;
        }
    }
`

export default SocialChannels
