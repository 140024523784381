import { withAssetPrefix } from 'gatsby'
import React from 'react'
import Helmet from 'react-helmet'
import pkg from '../../../package'

const Head = ({
    // eslint-disable-next-line max-len
    description = 'The decentralized P2P real-time data pub/sub network. Build Web3 projects that leverage the Streamr data crowdsourcing and monetisation infrastructure.',
    mediaTitle = 'Streamr',
    mediaDescription = 'The decentralized real-time data network',
    posterSize = [1200, 600],
    posterUrl = `https://streamr.network/resources/social/public-site.png?v=${pkg.version}`,
    title = `${mediaDescription} | ${mediaTitle}`,
}) => {
    const [width, height] = posterSize

    return (
        <Helmet>
            <title>{title}</title>
            <meta name="description" content={description} />
            <meta property="og:title" content={mediaTitle} />
            <meta property="og:description" content={mediaDescription} />
            <meta property="og:image" content={posterUrl} />
            <meta property="og:image:width" content={width} />
            <meta property="og:image:height" content={height} />
            <meta name="twitter:card" content="summary_large_image" />
            <meta name="twitter:title" content={mediaTitle} />
            <meta name="twitter:description" content={mediaDescription} />
            <meta name="twitter:image" content={posterUrl} />
            <meta name="msapplication-TileColor" content="#ffffff" />
            <meta name="msapplication-TileImage" content="/ms-icon-144x144.png" />
            <meta
                name="viewport"
                content="width=device-width, initial-scale=1, shrink-to-fit=no, user-scalable=no"
            />
            <meta name="version" content={pkg.version} />
            <meta name="commit" content={process.env.GATSBY_TRAVIS_COMMIT} />
            <meta name="format-detection" content="telephone=no" />
            {!process.env.GATSBY_ALLOW_ROBOTS && <meta name="robots" content="noindex" />}
            <link
                type="image/png"
                rel="icon"
                href={withAssetPrefix('/resources/favicon-16x16.png')}
                size="16"
            />
            <link
                type="image/png"
                rel="icon"
                href={withAssetPrefix('/resources/favicon-32x32.png')}
                size="32"
            />
            <link
                type="image/png"
                rel="icon"
                href={withAssetPrefix('/resources/favicon-96x96.png')}
                size="96"
            />
            <link
                type="image/png"
                rel="apple-touch-icon"
                href={withAssetPrefix('/resources/apple-icon-57x57.png')}
                size="57"
            />
            <link
                type="image/png"
                rel="apple-touch-icon"
                href={withAssetPrefix('/resources/apple-icon-60x60.png')}
                size="60"
            />
            <link
                type="image/png"
                rel="apple-touch-icon"
                href={withAssetPrefix('/resources/apple-icon-72x72.png')}
                size="72"
            />
            <link
                type="image/png"
                rel="apple-touch-icon"
                href={withAssetPrefix('/resources/apple-icon-76x76.png')}
                size="76"
            />
            <link
                type="image/png"
                rel="apple-touch-icon"
                href={withAssetPrefix('/resources/apple-icon-114x114.png')}
                size="114"
            />
            <link
                type="image/png"
                rel="apple-touch-icon"
                href={withAssetPrefix('/resources/apple-icon-120x120.png')}
                size="120"
            />
            <link
                type="image/png"
                rel="apple-touch-icon"
                href={withAssetPrefix('/resources/apple-icon-144x144.png')}
                size="144"
            />
            <link
                type="image/png"
                rel="apple-touch-icon"
                href={withAssetPrefix('/resources/apple-icon-152x152.png')}
                size="152"
            />
            <link
                type="image/png"
                rel="apple-touch-icon"
                href={withAssetPrefix('/resources/apple-icon-180x180.png')}
                size="180"
            />
            <link
                type="image/png"
                rel="icon"
                href={withAssetPrefix('/resources/android-icon-192x192.png')}
                size="192"
            />
            <style>{'@-ms-viewport { width: device-width; }'}</style>
            <html lang="en" />
        </Helmet>
    )
}

export default Head
