import React from 'react'
import { MqDesktop } from 'streamr-ui/css/consts'
import styled from 'styled-components'

export const GitHub = ({ className }) => (
    <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" className={className}>
        {/* eslint-disable-next-line max-len */}
        <path
            d="M11.999 0C5.373 0 0 5.279 0 11.791c0 5.21 3.438 9.629 8.207 11.188.6.109.819-.255.819-.568 0-.28-.01-1.021-.017-2.005-3.337.712-4.042-1.581-4.042-1.581-.546-1.362-1.332-1.725-1.332-1.725-1.09-.731.082-.717.082-.717 1.205.084 1.838 1.216 1.838 1.216 1.07 1.801 2.81 1.281 3.493.98.109-.762.419-1.282.762-1.577-2.665-.297-5.467-1.31-5.467-5.827 0-1.287.468-2.34 1.236-3.164-.124-.298-.536-1.498.118-3.12 0 0 1.007-.318 3.3 1.208A11.685 11.685 0 0 1 12 5.702c1.02.005 2.046.135 3.005.397C17.295 4.573 18.3 4.89 18.3 4.89c.655 1.623.243 2.823.12 3.12.77.824 1.233 1.878 1.233 3.165 0 4.53-2.806 5.526-5.478 5.818.43.364.814 1.084.814 2.184 0 1.576-.015 2.847-.015 3.234 0 .315.217.682.825.567C20.565 21.416 24 17 24 11.791 24 5.28 18.627 0 11.999 0"
            fill="currentColor"
            fillRule="evenodd"
        />
    </svg>
)

export const Medium = ({ className }) => (
    <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" className={className}>
        {/* eslint-disable-next-line max-len */}
        <path
            d="M0 0h24v24H0V0zm5.727 8.027v7.126a.82.82 0 0 1-.218.707l-1.694 2.054v.271h4.803v-.27L6.924 15.86a.85.85 0 0 1-.233-.707V8.99l4.215 9.195h.49l3.62-9.195v7.33c0 .195 0 .232-.128.36l-1.302 1.264v.271h6.322v-.27l-1.257-1.235a.376.376 0 0 1-.143-.36V7.281a.376.376 0 0 1 .143-.362l1.287-1.234v-.27h-4.456l-3.176 7.923-3.613-7.924H4.019v.271L5.524 7.5a.624.624 0 0 1 .203.527z"
            fill="currentColor"
        />
    </svg>
)

export const Discord = ({ className }) => (
    <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" className={className}>
        <path
            // eslint-disable-next-line max-len
            d="M12 0c6.6 0 12 5.4 12 12s-5.4 12-12 12S0 18.6 0 12 5.4 0 12 0zm-1.9 6.8c-.3 0-1.8.1-3.4 1.3 0 0-1.8 3.2-1.8 7.1 0 0 1 1.8 3.7 1.9 0 0 .3-.3.6-.7l.1-.1.1-.1V16c-1.5-.5-2.1-1.4-2.1-1.4s.1.1.3.2h.1s.1 0 .1.1c.3.2.6.3.9.4.5.2 1.1.4 1.8.5 1 .2 2.1.2 3.2 0 .6-.1 1.2-.3 1.8-.5.4-.2.9-.4 1.4-.7-.1.1-.7 1-2.2 1.4l.1.1.1.1c.3.4.7.9.7.9 2.7-.1 3.7-1.9 3.7-1.9 0-3.9-1.8-7.1-1.8-7.1-1.6-1.2-3.1-1.3-3.4-1.3l-.3.2c1.6.5 2.5 1.2 2.9 1.4l.1.1c-1.9-1-4-1.4-6.1-1.1h-.2c-.4 0-1.5.2-2.8.8-.4.2-.7.4-.7.4s1.1-1 3.2-1.6l-.1-.2zm-.3 4.6c.7 0 1.3.6 1.2 1.4 0 .7-.5 1.4-1.2 1.4s-1.2-.6-1.2-1.4c-.1-.8.5-1.4 1.2-1.4zm4.4 0c.7 0 1.2.6 1.2 1.4 0 .7-.5 1.4-1.2 1.4s-1.2-.6-1.2-1.4c0-.8.5-1.4 1.2-1.4z"
            fill="currentColor"
        />
    </svg>
)

export const Reddit = ({ className }) => (
    <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" className={className}>
        {/* eslint-disable-next-line max-len */}
        <path
            d="M15.8 14.195a1.8 1.8 0 1 1 0-3.6 1.8 1.8 0 0 1 0 3.6m-.048 3.004c-1.37.741-2.639.994-3.728.994-2.248 0-3.74-1.073-3.831-1.14a.48.48 0 0 1-.104-.664.462.462 0 0 1 .651-.105c.12.087 2.906 2.06 6.574.077a.464.464 0 0 1 .631.196.479.479 0 0 1-.193.642M6.8 12.395a1.8 1.8 0 1 1 3.6 0 1.8 1.8 0 0 1-3.6 0M19.8 2.2a1.4 1.4 0 0 1 0 2.799c-.772 0-1.4-.628-1.4-1.4 0-.772.628-1.4 1.4-1.4m4.2 8.215c0-1.499-1.231-2.718-2.744-2.718a2.75 2.75 0 0 0-1.837.7c-1.848-1.173-4.224-1.859-6.789-1.963l1.364-4.429 3.659 1.542c0 .016-.003.031-.003.047 0 1.205.99 2.185 2.206 2.185 1.217 0 2.207-.98 2.207-2.185 0-1.204-.99-2.184-2.207-2.184-.895 0-1.666.531-2.011 1.292L13.89 1.034a.434.434 0 0 0-.58.268l-1.577 5.119c-2.713.034-5.246.716-7.2 1.937a2.757 2.757 0 0 0-1.79-.662C1.231 7.696 0 8.916 0 10.414a2.69 2.69 0 0 0 1.254 2.279c-.046.269-.07.54-.07.812 0 1.928 1.144 3.728 3.222 5.067 2.02 1.302 4.698 2.02 7.54 2.02 2.842 0 5.52-.718 7.54-2.02 2.078-1.34 3.223-3.14 3.223-5.067 0-.25-.022-.501-.061-.75A2.709 2.709 0 0 0 24 10.414"
            fill="currentColor"
            fillRule="evenodd"
        />
    </svg>
)

export const Telegram = ({ className }) => (
    <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" className={className}>
        {/* eslint-disable-next-line max-len */}
        <path
            d="M11.995.045C5.37.045 0 5.415 0 12.04c0 6.624 5.37 11.994 11.995 11.994 6.624 0 11.994-5.37 11.994-11.994C23.99 5.415 18.62.045 11.995.045zM7.824 13.477l-3.027-1.02s-.464-.164-.509-.523c-.045-.36.524-.555.524-.555l12.03-4.719s.99-.434.99.285l-2.143 10.801s-.3.75-1.124.39l-3.048-2.337-1.956 1.783s-.152.116-.32.043l.395-3.37s5.483-4.928 5.708-5.138c.225-.21.15-.255.15-.255.015-.254-.405 0-.405 0l-7.265 4.615z"
            fill="currentColor"
            fillRule="evenodd"
        />
    </svg>
)

export const Trello = ({ className }) => (
    <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" className={className}>
        {/* eslint-disable-next-line max-len */}
        <path
            d="M21 0a3 3 0 0 1 3 3v18a3 3 0 0 1-3 3H3a3 3 0 0 1-3-3V3a3 3 0 0 1 3-3h18zm-1.56 3.12H15a1.44 1.44 0 0 0-1.44 1.44v7.62c0 .795.645 1.44 1.44 1.44h4.44a1.44 1.44 0 0 0 1.44-1.44V4.56a1.44 1.44 0 0 0-1.44-1.44zM9 3.12H4.56a1.44 1.44 0 0 0-1.44 1.44v13.62c0 .795.645 1.44 1.44 1.44H9a1.44 1.44 0 0 0 1.44-1.44V4.56A1.44 1.44 0 0 0 9 3.12z"
            fill="currentColor"
            fillRule="evenodd"
        />
    </svg>
)

export const Twitter = ({ className }) => (
    <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" className={className}>
        {/* eslint-disable-next-line max-len */}
        <path
            d="M7.539 21.592c9.056 0 14.012-7.54 14.012-14.067 0-.212 0-.425-.01-.637A10.055 10.055 0 0 0 24 4.324a9.95 9.95 0 0 1-2.833.78 4.97 4.97 0 0 0 2.17-2.738 9.773 9.773 0 0 1-3.13 1.196A4.906 4.906 0 0 0 16.614 2c-2.718 0-4.927 2.218-4.927 4.946 0 .386.048.762.125 1.128C7.72 7.872 4.09 5.895 1.66 2.906A4.96 4.96 0 0 0 1 5.394c0 1.716.874 3.23 2.19 4.117a4.966 4.966 0 0 1-2.23-.617v.067a4.95 4.95 0 0 0 3.948 4.85 4.784 4.784 0 0 1-1.297.174c-.317 0-.624-.03-.922-.087a4.927 4.927 0 0 0 4.6 3.432 9.853 9.853 0 0 1-6.117 2.121A9.44 9.44 0 0 1 0 19.384a13.968 13.968 0 0 0 7.539 2.208"
            fill="currentColor"
        />
    </svg>
)

export const Youtube = ({ className }) => (
    <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" className={className}>
        {/* eslint-disable-next-line max-len */}
        <path
            d="M23.498 6.64C24 8.524 24 12.455 24 12.455s0 3.93-.502 5.814a3.016 3.016 0 0 1-2.121 2.135c-1.872.505-9.377.505-9.377.505s-7.505 0-9.377-.505A3.016 3.016 0 0 1 .501 18.27C0 16.385 0 12.455 0 12.455s0-3.93.501-5.815a3.015 3.015 0 0 1 2.122-2.135C4.495 4 12 4 12 4s7.505 0 9.377.505a3.015 3.015 0 0 1 2.121 2.135zM9.545 16.023l6.273-3.568-6.273-3.569v7.137z"
            fill="currentColor"
            fillRule="evenodd"
        />
    </svg>
)

export const LinkedIn = ({ className }) => (
    <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" className={className}>
        {/* eslint-disable-next-line max-len */}
        <path
            d="M2.87 5.351c1.791 0 2.906-1.19 2.906-2.676C5.743 1.157 4.661 0 2.906 0 1.147 0 0 1.157 0 2.675c0 1.486 1.114 2.676 2.838 2.676h.033zM.304 22.95V7.465h5.135v15.484H.303zM8 22.847S8.067 8.816 8 7.364h5.135v2.245H13.1C13.776 8.552 14.992 7 17.763 7c3.378 0 5.911 2.213 5.911 6.97v8.877H18.54v-8.283c0-2.081-.743-3.502-2.602-3.502-1.418 0-2.263.958-2.634 1.883-.136.33-.17.793-.17 1.255v8.647H8z"
            fill="currentColor"
            fillRule="evenodd"
        />
    </svg>
)

const channels = {
    github: {
        url: 'https://github.com/streamr-dev',
        name: 'GitHub',
        icon: GitHub,
    },
    linkedIn: {
        url: 'https://www.linkedin.com/company/streamr-network/',
        name: 'LinkedIn',
        icon: LinkedIn,
    },
    medium: {
        url: 'https://medium.com/streamrblog',
        name: 'Medium',
        icon: Medium,
    },
    reddit: {
        url: 'https://reddit.com/r/streamr',
        name: 'Reddit',
        icon: Reddit,
    },
    telegram: {
        url: 'https://t.me/streamrdata',
        name: 'Telegram',
        icon: Telegram,
    },
    trello: {
        url: 'https://trello.com/b/nQQ9Zwvj/streamr-milestone-2',
        name: 'Trello',
        icon: Trello,
    },
    twitter: {
        url: 'https://twitter.com/streamr',
        name: 'Twitter',
        icon: Twitter,
    },
    youtube: {
        url: 'https://www.youtube.com/channel/UCGWEA61RueG-9DV53s-ZyJQ',
        name: 'Youtube',
        icon: Youtube,
    },
    discord: {
        url: 'https://discord.gg/gZAm8P7hK8',
        name: 'Discord',
        icon: Discord,
    },
}

export default function SocialLink({ className, channelKey }) {
    const { icon: Icon, name, url } = channels[channelKey]

    return (
        <Root href={url} target="_blank" rel="noopener noreferrer" className={className}>
            <Icon />
            <Wrap>
                <Label>{name}</Label>
            </Wrap>
        </Root>
    )
}

const Wrap = styled.div`
    align-items: center;
    bottom: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    left: 0;
    opacity: 0;
    pointer-events: none;
    position: absolute;
    transition: 200ms;
    transition-property: opacity, bottom;
    width: 100%;
`

const Root = styled.a`
    box-sizing: content-box;
    display: block;
    font-family: 'IBM Plex Mono', monospace; /* mono */
    font-weight: 400; /* regular */
    line-height: 1.5em;
    margin: -1em;
    padding: 1em;
    position: relative;
    text-align: center;
    text-decoration: none;
    text-transform: uppercase;
    width: 1.5em;

    svg {
        display: block;
        font-size: 1.5em;
        line-height: 1em;
        position: relative;
        top: 0;
        transition: 200ms top;
        transform: translateZ(0);
    }

    &,
    &:link,
    &:active,
    &:focus,
    &:hover,
    &:visited {
        color: inherit;
    }

    &:active svg,
    &:hover svg {
        top: -0.25em;
    }

    &:active ${Wrap}, &:hover ${Wrap} {
        bottom: -0.25em;
        opacity: 1;
    }

    @media ${MqDesktop} {
        width: 2rem;

        svg {
            width: 2rem;
        }
    }
`

const Label = styled.div`
    font-size: 0.75em;
    letter-spacing: 1px;
    white-space: nowrap;
`
