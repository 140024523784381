module.exports = {
    "language": "English",
    "design": {
        "assets": {
            "images": {
                "title": "Images",
                "description": "Writing an article, blog post or feature about Streamr that needs imagery? We've got you covered with a selection of images below, mostly featuring work by our illustrator Stuart Wade. All images at 1600 x 600 pixels.",
                "network": "Network",
                "dataUnions": "Data Unions",
                "marketplace": "Marketplace",
                "core": "Core",
                "techStack": "Tech Stack",
                "smartCity": "Smart City",
                "brand": "Streamr Brand",
                "iot": "Internet of Things",
                "design": "Streamr Design",
                "productDesign": "Streamr Product Design",
                "automotive": "Streamr Automotive Industry",
                "industrial": "Streamr Industrial",
                "renewables": "Streamr Renewables"
            },
            "colours": {
                "title": "Colours",
                "description": "If you’re creating anything based on the Streamr brand, below are the references for the key colours used.",
                "downloadBrandGuide": "Download simple brand guide"
            },
            "typography": {
                "title": "Typography",
                "description": "Streamr’s apps are open source, and so our fonts also need to be. We use Plex Sans and Plex Mono, developed by Bold Monday for IBM. It is quite a large family, with plenty of flexibility and the companion Mono was especially useful to us in redesigning the patch-based UI for the Streamr editor to be more regular and consistent."
            }
        },
        "hero": {
            "description": "Here you’ll find all the design assets for representing Streamr digitally. Everything is provided under a Creative Commons sharealike license. Please try and avoid crimes against whitespace and wild flights of photoshoppery.",
            "title": "Brand assets"
        },
        "logoAssets": {
            "logo": {
                "horizontal-positive-mono-lockup": "Horizontal positive mono lockup",
                "horizontal-colour-lockup": "Horizontal colour lockup",
                "horizontal-reversed-mono-lockup": "Horizontal reversed mono lockup",
                "vertical-positive-mono-lockup": "Vertical positive mono lockup",
                "vertical-colour-lockup": "Vertical colour lockup",
                "vertical-reversed-mono-lockup": "Vertical reversed mono lockup",
                "positive-mono-mark": "Positive mono mark",
                "colour-mark": "Colour mark",
                "reversed-mono-mark": "Reversed mono mark"
            }
        }
    },
    "general": {
        "top": "Top",
        "swash": "Swash",
        "tracey": "Tracey",
        "machineWitness": "Machine Witness",
        "caseStudies": "Case studies",
        "dataToken": "DATA Token",
        "dataUnions": "Data Unions",
        "theNetwork": "The Network",
        "browseMarketplace": "Browse Marketplace",
        "about": "About",
        "builtOnStreamr": {
            "desktop": "Projects built on Streamr",
            "mobile": "Built on Streamr"
        },
        "apps": "Apps",
        "blog": "Blog",
        "build": "Build",
        "community": "Community",
        "design": "Design",
        "designAssets": "Design Assets",
        "docs": "Docs",
        "editor": "Editor",
        "github": "GitHub",
        "home": "Home",
        "marketplace": "Marketplace",
        "medium": "Medium",
        "network": "Network",
        "project": "Project",
        "org": "Org",
        "reddit": "Reddit",
        "trello": "Trello",
        "twitter": "Twitter",
        "gotoMarketplace": "Go to Marketplace",
        "getStarted": "Get Started",
        "youtube": "Youtube",
        "linkedIn": "LinkedIn",
        "mission": "Mission",
        "signIn": "Sign in",
        "signUp": "Sign up",
        "visitTheDocs": "Visit the docs",
        "haveAccountAlready": "Already have an account?",
        "story": "Story",
        "streamrBlog": "Streamr Blog",
        "meetTeam": "Meet the team",
        "platform": "The Platform",
        "networkApps": "Network Apps",
        "learnMore": "Learn more",
        "browseData": "Browse real-time data",
        "createStream": "Create a stream",
        "createProduct": "Create a product",
        "createDataUnion": "Create a data union",
        "marketCap": "Market cap",
        "24hVolume": "24h volume",
        "papers": "Papers",
        "feature": {
            "integrate": {
                "title": "Integrate",
                "description": "Build bridges between existing APIs and Streamr"
            },
            "prototype": {
                "title": "Prototype",
                "description": "Quickly build reactions to real-time data"
            },
            "oracle": {
                "title": "Build Oracles",
                "description": "Easily build rules to interact with Ethereum smart contracts"
            },
            "share": {
                "title": "Share and discover",
                "description": "Tap into a global pool of data streams"
            },
            "monetise": {
                "title": "Monetise",
                "description": "Get paid for the data you produce"
            },
            "crowdsell": {
                "title": "Crowdsell",
                "description": "Incentivize production of previously unavailable data"
            },
            "create": {
                "title": "Create"
            },
            "collaborate": {
                "title": "Collaborate",
                "description": "Share, collaborate and publish your work"
            },
            "analytics": {
                "title": "Analytics",
                "description": "Basic filtering, aggregation and rolling statistics"
            }
        },
        "products": "Products",
        "streams": "Streams",
        "gettingStarted": "Getting Started",
        "whitepaper": "Whitepaper",
        "governanceWhitepaper": "Governance Whitepaper",
        "terms": "Terms & Conditions",
        "privacy": "Privacy"
    },
    "contacts": {
        "general": "General",
        "media": "Media",
        "jobs": "Jobs",
        "business": "Business"
    },
    "top": {
        "hero": {
            "title": "Streamr is the missing real-time data protocol for the decentralized web",
            "description": "Publish and subscribe to cryptographically secure data streams with a few lines of code, trade real-time data on the Marketplace or try Core, our real-time data toolkit.",
            "videoTitle": "Explanatory video"
        },
        "mission": "Streamr’s mission is to build decentralized infrastructure for real-time data, replacing centralized message brokers with a global peer-to-peer network. By relying on cryptography instead of trust, the open-source network enables data sharing and monetization in IoT and smart cities, business consortia, and the decentralized web&nbsp;(Web&nbsp;3.0).",
        "story": "Streamr is a distributed open source project crowdfunded in 2017, with contributors across the world. It was started in reaction to the dominance of the Big Five tech giants, with the core belief that decentralized infrastructure can deliver a healthier distribution of money and power, as well as unlocking brand new use cases.",
        "platform": {
            "codeblockTitle": "Publishing data to a stream",
            "network": {
                "title": "Streamr Network",
                "subtitle": "P2P pub/sub for real-time data",
                "description": "The Streamr Network is a decentralized, scalable publish/subscribe network, designed for low-latency, real-time data from IoT sensors, machines, and other smart devices — a neutral data backbone for the machine data economy. It is simple to work with, the 5 lines of code below show how to send your data to the network."
            },
            "marketplace": {
                "title": "Data Marketplace",
                "subtitle": "Buy & sell real-time data",
                "description": "The Data Marketplace is an open source app built on top&nbsp;of&nbsp;the Network that&nbsp;enables anyone to easily share or&nbsp;monetise real-time data. Using the Core app, you can create data products from sensors and other sources, or build & sell crowdsourced data products with the new Data&nbsp;Unions feature. It can also be forked to enable private&nbsp;instances."
            },
            "dataunion": {
                "title": "Data Unions"
            },
            "userpages": {
                "title": "Userpages"
            },
            "stream": {
                "title": "Stream"
            }
        },
        "token": {
            "headline": "The DATA Token",
            "description": "The DATA token is an ERC-20 token that is used for <a href=\"https://snapshot.org/#/streamr\" target=\"_blank\" rel=\"noopener noreferrer\">project governance</a>, to incentivize the Network, delegating stake on Streamr nodes, and for payments on the Marketplace. It is trading on Uniswap and a range of centralized exchanges. Check out a <a href=\"https://messari.io/asset/streamr/profile\" target=\"_blank\" rel=\"noopener noreferrer\">project profile</a>, <a href=\"https://coinmarketcap.com/currencies/streamr/\" target=\"_blank\" rel=\"noopener noreferrer\">Coinmarketcap</a> listing or the <a href=\"https://etherscan.io/token/{{contractAddress}}\" target=\"_blank\" rel=\"noopener noreferrer\">token contract</a>.",
            "exploreTokenomics": "Explore Tokenomics"
        },
        "blogposts": {
            "title": "News and Dev updates"
        }
    },
    "partners": {
        "caption": "Some of our partners"
    },
    "learn": {
        "network": {
            "hero": {
                "title": "Streamr Network",
                "description": {
                    "desktop": "The Streamr Network is a decentralized, topic-based publish-subscribe system. Each stream or pub-sub topic has its own peer-to-peer overlay network that is built and maintained by a set of BitTorrent-like trackers. Development is still in progress, with Tatum as the current milestone.",
                    "mobile": "The Streamr Network is still under active development, with Tatum as the current major milestone."
                }
            },
            "datapoints": {
                "loading": "Loading live network stats",
                "pointsPerSecond": "Messages per second currently on the Network"
            },
            "usage": {
                "title": "Using the Network",
                "description": {
                    "1": "You can easily use the Network in your own applications via the SDKs or by directly calling the API. Official or community-built SDKs are available for JS, Java and Python, with more in the works.",
                    "2": "These simple code examples show the basics of publishing and subscribing to a stream. For more details, dive into our&nbsp;docs."
                },
                "produce": "Publishing data to a stream",
                "subscribe": "Subscribing to a stream"
            },
            "integrations": {
                "title": "Integrations"
            },
            "whyBuild": {
                "title": "Why build it?",
                "incentivisedScaling": {
                    "title": "Incentivised scaling",
                    "description": "Centralized message brokers have inherent scaling and economic incentive problems. The Streamr Network solves the scaling issue with distributed message brokering over a custom peer-to-peer network architecture. In later milestones, cryptocurrency, cryptographic proofs, and game theory will be used to incentivize people and organizations to run Streamr nodes.<br /><br />Streamr DATAcoin (DATA) is the Ethereum ERC-20 token representing value in the Streamr ecosystem. The companion blockchain is used for value settlement, identity, and permission control, while the data itself stays in the scalable Streamr Network."
                },
                "publishSubscribe": {
                    "title": "Publish/Subscribe",
                    "description": "The pub/sub messaging pattern is a highly useful primitive for data-driven applications, because it decouples data publishers from data subscribers. This simplifies applications and makes them more secure.<br /><br />However, pub/sub is not provided in TCP/IP, the core protocol of the internet, meaning that developers of applications such as instant messengers, financial market data delivery, multiplayer games, or data-driven IoT applications have so far needed to resort to centralized message brokers.<br /><br />This not only creates a single point of failure, but also exposes your precious data to service providers."
                },
                "simpleSetup": {
                    "title": "Simple setup",
                    "description": "For application developers, data publishers simply fire and forget. No need to set up APIs, data silos, or open ports that could compromise security. Just send new data points to the Network and you’re done. This makes integration very easy. For data consumers, they simply listen.<br /><br />There is no need to know where the data sources are, which IP address to reach them on, or how to interface with them. They don’t need to open server ports either. They just connect to the network, subscribe to what they need, and react to incoming messages."
                }
            },
            "howItWorks": {
                "title": "How does it work?",
                "brokerNodes": {
                    "title": "Streamr nodes",
                    "description": "Streamr nodes contribute bandwidth and storage to the network, and earn DATA paid as usage fees by users of the Network. In a sense, running a Streamr node is somewhat comparable to mining, but instead of solving an CPU/GPU-bound artificial problem and wasting energy, nodes provide useful network resources which, using the Streamr protocol, collectively produce the message transport service available to applications. <br /><br />Together, the P2P structure and incentive mechanism enable decentralization, which means that the network can operate without any central party controlling your data or generating value out of it."
                },
                "runningNode": {
                    "title": "Running a node",
                    "description": "The network is a result of many people running Streamr nodes, collectively forming the peer-to-peer network and pub/sub messaging service.<br /><br />The Streamr node is a piece of software you install, intended to be run on always-on machines with stable bandwidth. It connects to other nodes in the network and starts to route data traffic through your computer, and rewards you for it in DATA.<br /><br />We expect to open the network to public untrusted nodes in the Brubeck stage, scheduled for 2021."
                }
            },
            "roadmap": {
                "milestones": "Milestone 1 on Trello"
            }
        },
        "marketplace": {
            "hero": {
                "title": "Streamr Marketplace.<br />Real-time data on demand",
                "description": "The Marketplace dapp provides an easy way to buy, sell and discover data streams, from simple price feeds and sensor data to massive crowdsourced Data Unions with thousands of providers. Buy with DATA, ETH or DAI via Uniswap integration.",
                "browseMarketplace": "Browse Marketplace",
                "marketplaceDocs": "Marketplace Docs"
            },
            "quickstart": {
                "title": "Quickstart Guide",
                "web3": {
                    "title": "Install a Web3 wallet",
                    "description": "MetaMask is the most useful choice to interact with the&nbsp;Marketplace"
                },
                "wallet": {
                    "title": "Get ETH via MyEtherWallet",
                    "description": "If you don’t have any ETH, you can buy some through a&nbsp;MEW&nbsp;integration"
                },
                "protocol": {
                    "title": "Get DATA via Uniswap",
                    "description": "Uniswap is an easy way to swap ETH for&nbsp;DATA",
                    "descriptionDesktop": "Uniswap is an easy way<br />to swap ETH for DATA"
                },
                "connectWallet": "Connect a wallet"
            },
            "usage": {
                "title": "Using the Marketplace",
                "readTheDocs": "Read the Docs",
                "selling": {
                    "title": {
                        "sm": "Selling",
                        "xl": "Selling on the Marketplace"
                    },
                    "description": {
                        "desktop": "On the Streamr Marketplace, you can start monetising any real-time data that you produce or own the rights to. Integrate your data, create a product and start earning DATA. Using the Core app, integrating your data and creating streams is simple.<br /><br />Then just create a product from Core &gt; Products. Set the name, description, category, and the price for your data and you are good to go. The video walks you through the Product Creation flow, and you can learn how to integrate data and create streams here.",
                        "mobile": "On the Streamr marketplace, you can start monetising any real-time data that you produce or own the rights to. The video below walks you through the Product Creation flow, and you can learn how to integrate data and create streams here."
                    }
                },
                "buying": {
                    "title": {
                        "sm": "Buying",
                        "xl": "Buying on the Marketplace"
                    },
                    "description": "Once you have followed the Quickstart guide above, Marketplace purchases are pretty simple. Browse the product tiles, or use the live search to find exactly what you need. View details on the product pages. Make purchases with your wallet. Watch the video for a walkthrough of the purchase process."
                }
            }
        },
        "dataUnions": {
            "requestDemo": {
                "workEmail": "Work email",
                "request": "Request demo",
                "visitTheDocs": "Visit the docs"
            },
            "caseStudies": {
                "title": "Data Unions in use"
            },
            "whyBuild": {
                "title": "Why build a Data Union?"
            },
            "graph": {
                "desktop_caption": "Typical revenue and data flows for a Data Union"
            },
            "getStarted": {
                "mobile_title": "Get started",
                "desktop_title": "Get started with Data Unions"
            }
        }
    },
    "teamSection": {
        "memberList": {
            "productDevelopment": {
                "title": "Product"
            },
            "businessDevelopment": {
                "title": "Business<br />Development"
            },
            "design": {
                "title": "Design"
            },
            "ecosystemAndMarketing": {
                "title": "Ecosystem &amp;<br />Marketing"
            },
            "operations": {
                "title": "Operations"
            }
        },
        "ericAndrews": {
            "description": "With Streamr since 2015, Eric is a developer with eight years of industry experience and a maths-oriented education in computer science. He is interested in software architecture, machine learning, algorithms, and clean code and previously worked on Alma Media’s digital news operations for half-a-decade.",
            "title": "Lead Developer"
        },
        "francineIhenacho": {
            "description": "Francine is a Cambridge-educated Marketer with over 10 years of experience in both B2B and B2C marketing. During her career, she has led campaigns for technology brands such as Dell, Lenovo, Microsoft and AMD, and the reputable engineering firm Arup, where she managed the launch of Mathematics: The Winton Gallery at The Science Museum, in collaboration with Zaha Hadid Architects.",
            "title": "Head of Marketing"
        },
        "henriPihkala": {
            "description": "Founded Streamr to make data more available, fair, and valuable for all. Built scalable infrastructure & tools for real-time data for 10 years. Passionate about the decentralization of applications and data infrastructure. Previously CTO at two algorithmic trading companies, one of which he co-founded, and CEO at a startup building cloud-based data infrastructure for IoT.",
            "title": "Founder, CEO"
        },
        "jarmoSuoranta": {
            "description": "Jarmo is the former COO of Sujuwa, a successful Finnish based technology company, and has worked in various software development and leadership positions for nearly 15 years. He has an MSc from the University of Helsinki and enjoys running marathons in under three hours.",
            "title": "Business executive"
        },
        "jarnoMarttila": {
            "description": "Jarno is full-stack software developer and jack of all trades. Previously he's worked as a senior software developer at Sujuwa, a private consultant, and a researcher. Converts coffee to code and dreams about sending 7A's on boulders. He has a MSc in Computer Science. ",
            "title": "Developer"
        },
        "julietaArenasMoran": {
            "description": "Julieta spent several years in sales and marketing, working for diverse companies in the public sector in Mexico. Before starting with Streamr in 2018, she had a practise as a private language teacher, and was also employed by ARBIS, in the adult education sector in Finland. She studied Spanish Philology at the University of Puebla, Mexico and a programme in Business and Administration in Finland.",
            "title": "Office Operations Manager"
        },
        "juusoTakalainen": {
            "description": "Juuso is a full-stack developer and all-around software engineer. He's worked full time on Ethereum applications since 2016. His interests include recreational mathematics, the game of go (weiqi), blockchain wizardry, and language learning.",
            "title": "Developer"
        },
        "kareNuorteva": {
            "description": "Kare is a hands-on software architect with 20 years of experience. He has previously worked for Satama Interactive and Reaktor. He is passionate about simplicity and spicy curry.",
            "title": "Developer"
        },
        "mariuszRolinski": {
            "description": "Mariusz is a frontend focused web developer with almost 10 years of experience. He values simplicity, consistency and attention to detail. He loves exploring new technologies, reading about post-apocalyptic worlds, and playing ArmA.",
            "title": "Developer"
        },
        "mattInnes": {
            "description": "Matt has been designing for cryptocurrency since 2013. He has led design for projects like Hivewallet, Counterparty, Cobalt, Vizor and Golem. He runs his own digital product design studio, Idealogue. He is a graduate of Swinburne Design School, Melbourne.",
            "title": "Special Advisor"
        },
        "matthewFontana": {
            "description": "Matthew is a passionate digital product builder and serial entrepreneur with over 10 years’ experience in building applications for leading consultancies in Australia and Finland. He has also founded award winning startup companies in the education and travel sectors and holds a degree in Electrical Power Engineering.",
            "title": "Head of Ecosystem"
        },
        "michaelMalka": {
            "description": "Part of the software industry for almost 20 years in various roles, from developer, to specialist, to CEO. In total Michael has co-founded three companies and his current special interests include Blockchain and Artificial Intelligence.",
            "title": "Special Advisor"
        },
        "nikkeNylund": {
            "description": "Serial entrepreneur and outside-the-box thinker with several successful exits. Among his successes is Quartal, with Quartal Flife sold to Investis Ltd (UK), Quartal Financial Solutions acquired by KMU Capital AG, and Quartal Content Management bought by Satama Plc.",
            "title": "Founder"
        },
        "ristoKarjalainen": {
            "description": "Ph.D. from the Wharton School at U. Penn — behavioural finance, machine learning & evolutionary computation. Quant and portfolio manager with JP Morgan & Merrill Lynch before getting hooked on real-time data and decentralized computing.",
            "title": "COO"
        },
        "robHolmes": {
            "description": "Rob has a decade of experience working within the transportation industry providing consultancy and project services at global engineering companies. Including projects involving Smart Motorways and data / information services. He has a keen interest in Blockchain and decentralization and its great potential to be a disruptor in the transportation industry and beyond.",
            "title": "Enterprise Partnerships"
        },
        "minhNguyen": {
            "description": "A native from Hanoi, Vietnam, Min has over a decade of experience in design and production of online services and mobile applications. He is currently working as a Design Lead at Helsinki based digital agency, Sangre. His designs are embodied by finding the best balance between the emotional and the rational, between the aesthetic and the functional. He believes unequivocally that a well-minded design will make things better; better for people, better for business and better for our planet.",
            "title": "Lead Designer"
        },
        "saoriKajiwara": {
            "description": "A native of Shizuoka, Japan, Saori holds a degree in Communication Design from Swinburne Design School, Melbourne. She is most comfortable when building animations and prototypes with her toolkit of Sketch, Abstract, Principle and InVision studio.",
            "title": "UI/UX Designer"
        },
        "tomHamilton": {
            "description": "Before coming to Streamr Tom held various roles in the tech communication industry, most recently for blockchain startup, the Pillar Project. He supports the decentralization of data storage, holds a masters in scriptwriting, and has a love for natural science and classical music composition.",
            "title": "Social Media Strategist"
        },
        "tuomasKoponen": {
            "description": "Tuomas is a well-seasoned software professional with lots of experience in building applications and digital products for small and big companies. M.Sc. in Software Engineering and a passion for finding simple solutions to complex problems.",
            "title": "Developer"
        },
        "timOxley": {
            "title": "Developer",
            "description": "Modern web applications & Javascript specialist. International speaker, founder & co-organiser of CampJS Australia & SingaporeJS. Co-author of NodeJS in Action 2nd Edition, ex-host of NodeUP podcast. Previously worked in JS for embedded systems, developer tooling for enterprise-scale NodeJS, visual programming for supercomputer-powered tomography, and an interactive editor for WebVR."
        },
        "jonathanWolff": {
            "title": "Special Advisor",
            "description": "Jonathan is a technical Swiss army knife and entrepreneur with 18 years of work experience as a programmer, quant and data scientist. He has consulted for Bloomberg, Blackrock, and Yahoo!, and holds a bachelors in Physics magna cum laude from Harvard University and a MS in Mechanical Engineering from Columbia University. He enjoys creating and tinkering at his home in the woods of Vermont."
        },
        "marleneRonstedt": {
            "title": "Head of Crypto Relations",
            "description": "Writer and artist with experience in the Berlin startup scene, Marlene's writing has appeared in WIRED Germany and Coindesk. Artistically involved with the Antwerp gallery FP24 and the co-ownership project C2."
        },
        "santeriJuslenius": {
            "title": "Developer",
            "description": "Santeri is a software developer with interests in Distributed systems and Data science. He holds an MSc in computer science with a focus on networking. He joined the team in 2018 with an interest in P2P systems and did his master’s thesis on the Streamr Network’s P2P overlays. Plan B; become a rock star/street musician."
        },
        "teoGebhard": {
            "title": "Developer",
            "description": "Teo is a full-stack developer and software architect. He has strong work experience since the beginning of the millennium. Previously he has built data analysis solutions e.g. for the finance industry. He prefers simple and straightforward solutions which are easily maintained."
        },
        "petriSavolainen": {
            "title": "P2P Researcher",
            "description": "“A comfort zone is a beautiful place but nothing ever grows there.” ― Unknown Author"
        },
        "anthonyWuyts": {
            "title": "Head of Community",
            "description": "Anthony was implicated since 2017 in several crypto startups to grow communities. He has worked for trading - investment platforms, DAO's and is currently advisor for several of them. He owns a French-speaking community that allows people to learn all the complexities concerning this new decentralised world that is offered to us. The secret of change is to focus all of your energy, not on fighting the old but on building the new (Socrates). "
        },
        "bernatCanal": {
            "title": "Developer",
            "description": "Bernat is a full-stack developer with almost 10 years of experience. Based in Barcelona, he has been working on media distribution and decentralized architectures, as well as consulted for web and Blockchain projects."
        }
    },
    "project": {
        "papers": {
            "title": "Streamr Papers",
            "description": "Get access to the range of project-related technical and academic papers the Streamr team have published here, from the original whitepaper to governance, audits and technical ideas. All new publications will be made available on this page.",
            "whitepaper": {
                "title": "Streamr whitepaper",
                "date": "July 25, 2017"
            },
            "lightpaper": {
                "title": "Streamr lightpaper",
                "date": "May 4, 2022"
            },
            "multicastEncryption": {
                "title": "Multicast Encryption",
                "date": "August 22, 2022"
            },
            "networkWhitepaper": {
                "title": "Network whitepaper",
                "date": "August 20, 2020"
            },
            "tokenAudit": {
                "title": "Token sale audit",
                "date": "October 27, 2017"
            },
            "governanceWhitepaper": {
                "title": "Decentralized governance",
                "date": "March 5, 2020"
            },
            "limeChainAudit": {
                "title": "DATAv2 audit (LimeChain)",
                "date": "July 13, 2021"
            },
            "isentropyAudit": {
                "title": "DATAv2 audit (Isentropy)",
                "date": "June 29, 2021"
            },
            "tokenomicsAudit": {
                "title": "Tokenomics audit",
                "date": "November 3, 2023"
            }
        }
    },
    "caseStudy": {
        "readMore": "Read more",
        "readMoreAbout": "Read more about {{study}}",
        "machineWitness": {
            "desc": "As part of the The Trusted IoT Alliance (TIoTA)’s Smart E-Mobility Challenge, Streamr partnered with Bosch Software Innovations and Riddle & Code, on a pilot project that gathered a range of electric vehicle data and&nbsp;shared it openly in real-time. The project was awarded a&nbsp;Silver&nbsp;medal at the TioTA awards."
        },
        "swash": {
            "desc": "A browser extension called Swash has become the world&apos;s first digital Data Union. Introduced at Mozfest in&nbsp;London in October 2019, Swash allows users to capture their browsing data, and then sell it in real-time&nbsp;via a Data Union on the data Marketplace, with one&#8209;to&#8209;many micropayments."
        },
        "tracey": {
            "desc": "A partnership between The World Wide Fund for Nature&nbsp;(WWF) Philippines, UnionBank and Streamr&apos;s consultancy partner TX is working on Tracey, an app for&nbsp;fishermen that will use blockchain for documenting and verifying catch and traceability data. The provided data can then be used by financial institutions such as UnionBank to conduct credit assessments to be able to&nbsp;provide micro loans to the fishermen."
        },
        "paveMotors": {
            "desc": "Pave are building radically simple electric bikes, and rethinking how to move people and their data. From ERC-721 tokens for ownership and bike sharing to light nodes moving metrics on the Streamr Network and unlocking data monetisation for owners, Pave and Streamr are creating the future of personal transport."
        }
    }
};